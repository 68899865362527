import { Filters } from "../Filters";

class DataFilt { 

    filterData = [
        {
            id: 1,
            check: false,
            number: '14256',
            date: '04.12.20',
            dateHours:'21:58:17',
            type: 'Комиссия',
            subType: 'Партнер - ПС',
            initiator: 'ПС',
            sender: 'ИП Упоров...',
            subSender: 'Москва',
            recipient: 'ПС',
            subRecipient: null,
            sum: '24',
            card: '0005',
            comm_us: false,
            comm_ps: true,
            numberTrans: '546',
            state: 'Исполнено',
            approved: '04.12.20',
            subApproved: '22:03:51'
        },
        {
            id: 2,
            check: false,
            number: '14256',
            date: '04.12.20',
            dateHours:'21:58:17',
            type: 'Комиссия',
            subType: 'Партнер - ПС',
            initiator: 'ПС',
            sender: 'ИП Упоров...',
            subSender: 'Москва',
            recipient: 'ПС',
            subRecipient: null,
            sum: '24',
            card: '0005',
            comm_us: true,
            comm_ps: true,
            numberTrans: '546',
            state: 'Исполнено',
            approved: '04.12.20',
            subApproved: '22:03:51'
        },
        {
            id: 3,
            check: false,
            number: '14256',
            date: '04.12.20',
            dateHours:'21:58:17',
            type: 'Комиссия',
            subType: 'Партнер - ПС',
            initiator: 'ПС',
            sender: 'ИП Упоров...',
            subSender: 'Москва',
            recipient: 'ПС',
            subRecipient: null,
            sum: '24',
            card: '0005',
            comm_us: false,
            comm_ps: false,
            numberTrans: '546',
            state: 'Исполнено',
            approved: '04.12.20',
            subApproved: '22:03:51'
        },
        {
            id: 4,
            check: false,
            number: '14256',
            date: '04.12.20',
            dateHours:'21:58:17',
            type: 'Комиссия',
            subType: 'Партнер - ПС',
            initiator: 'ПС',
            sender: 'ИП Упоров...',
            subSender: 'Москва',
            recipient: 'ПС',
            subRecipient: null,
            sum: '24',
            card: '0005',
            comm_us: false,
            comm_ps: true,
            numberTrans: '546',
            state: 'Исполнено',
            approved: '04.12.20',
            subApproved: '22:03:51'
        },
        {
            id: 5,
            check: false,
            number: '14256',
            date: '04.12.20',
            dateHours:'21:58:17',
            type: 'Комиссия',
            subType: 'Партнер - ПС',
            initiator: 'ПС',
            sender: 'ИП Упоров...',
            subSender: 'Москва',
            recipient: 'ПС',
            subRecipient: null,
            sum: '24',
            card: '0005',
            comm_us: false,
            comm_ps: false,
            numberTrans: '546',
            state: 'Исполнено',
            approved: '04.12.20',
            subApproved: '22:03:51'
        },
        {
            id: 6,
            check: false,
            number: '14256',
            date: '04.12.20',
            dateHours:'21:58:17',
            type: 'Комиссия',
            subType: 'Партнер - ПС',
            initiator: 'ПС',
            sender: 'ИП Упоров...',
            subSender: 'Москва',
            recipient: 'ПС',
            subRecipient: null,
            sum: '24',
            card: '0005',
            comm_us: false,
            comm_ps: true,
            numberTrans: '546',
            state: 'Исполнено',
            approved: '04.12.20',
            subApproved: '22:03:51'
        },
        {
            id: 7,
            check: false,
            number: '14256',
            date: '04.12.20',
            dateHours:'21:58:17',
            type: 'Комиссия',
            subType: 'Партнер - ПС',
            initiator: 'ПС',
            sender: 'ИП Упоров...',
            subSender: 'Москва',
            recipient: 'ПС',
            subRecipient: null,
            sum: '24',
            card: '0005',
            comm_us: false,
            comm_ps: true,
            numberTrans: '546',
            state: 'Исполнено',
            approved: '04.12.20',
            subApproved: '22:03:51'
        },
        {
            id: 8,
            check: false,
            number: '14256',
            date: '04.12.20',
            dateHours:'21:58:17',
            type: 'Комиссия',
            subType: 'Партнер - ПС',
            initiator: 'ПС',
            sender: 'ИП Упоров...',
            subSender: 'Москва',
            recipient: 'ПС',
            subRecipient: null,
            sum: '24',
            card: '0005',
            comm_us: false,
            comm_ps: true,
            numberTrans: '546',
            state: 'Исполнено',
            approved: '04.12.20',
            subApproved: '22:03:51'
        },
        {
            id: 9,
            check: false,
            number: '14256',
            date: '04.12.20',
            dateHours:'21:58:17',
            type: 'Комиссия',
            subType: 'Партнер - ПС',
            initiator: 'ПС',
            sender: 'ИП Упоров...',
            subSender: 'Москва',
            recipient: 'ПС',
            subRecipient: null,
            sum: '24',
            card: '0005',
            comm_us: false,
            comm_ps: true,
            numberTrans: '546',
            state: 'Исполнено',
            approved: '04.12.20',
            subApproved: '22:03:51'
        },
        {
            id: 10,
            check: false,
            number: '14256',
            date: '04.12.20',
            dateHours:'21:58:17',
            type: 'Комиссия',
            subType: 'Партнер - ПС',
            initiator: 'ПС',
            sender: 'ИП Упоров...',
            subSender: 'Москва',
            recipient: 'ПС',
            subRecipient: null,
            sum: '24',
            card: '0005',
            comm_us: false,
            comm_ps: true,
            numberTrans: '546',
            state: 'Исполнено',
            approved: '04.12.20',
            subApproved: '22:03:51'
        },
    ]

    
}

export default new DataFilt();