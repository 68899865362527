import '../style/main.css';
import { ClassArea } from '../components/area/job area/job-area';
import { InArea } from '../components/area/info area/info-area';
import  Header from './header';
import { Banner } from '../components/banner/banner';

import Avatar from '../style/assets/img/photo_2024-03-14_15-02-49.jpg';
import FunSun from '../style/assets/fun sun.svg';
import BatIt from '../style/assets/bat it 1.svg';
import NovaDacha from '../style/assets/nova dacha 1.svg';
import Houdini from '../style/assets/houdini.png';
import AfterEffect from '../style/assets/after.png';
import Redshift from '../style/assets/redshift.png';
import Karma from '../style/assets/karma.png';
import Cinema from '../style/assets/cinema.png';
import BannerImg from '../../src/components/video/showreel.mp4';


import Work from '../style/assets/icons/Work.svg';
import Profile from '../style/assets/icons/Profile.svg';
import Location from '../style/assets/icons/Location.svg';
import Message from '../style/assets/icons/Message.svg';
import {ProjArea}  from '../components/area/project area/projects-area';
import MainProject from './projects';

function HomePage() {

/*
////////////////////////////////////
////////////////////////////////////
Информация о прошлых местах работы
////////////////////////////////////
////////////////////////////////////
*/
    const funJob = new ClassArea(
        'FUN&SUN', 
        'Моушен Дизайнер', 
        'Октябрь 2021',
        'Февраль 2024', 
        FunSun
    );

    const batJob = new ClassArea(
        'BAT IT', 
        'UX UI дизайнер', 
        'Ноябрь 2020',
        'Октябрь 2021', 
        BatIt
    );

    const novaJob = new ClassArea(
        'Nova Dacha', 
        'Графич. дизайнер', 
        'Март 2020',
        'Ноябрь 2020', 
        NovaDacha
    );

/*
////////////////////////////////////
////////////////////////////////////
Общая информация обо мне
////////////////////////////////////
////////////////////////////////////
*/
    const workInfo = new InArea(
        'В активном поиске',
        Work
    )

    const profileInfo = new InArea(
        '27 лет, высшее образов.',
        Profile
    )

    const locationInfo = new InArea(
        'Иркутск, удаленная работа',
        Location
    )

    const messageInfo = new InArea(
        'tg: @v_eropov',
        Message
    )


/*
////////////////////////////////////
////////////////////////////////////
Кнопка и баннер
////////////////////////////////////
////////////////////////////////////
*/



const banner = new Banner(
    '',
    '',
    BannerImg
)

    return (
        <div className="container">
        <Header />
        <div className="main">
            <div className="container_main-1">
                { banner.BannerDots() }
                <div className="sub_cont">
                    <p className='title_cont'>Обо мне</p>
                    <div className="about_me">
                        <div className="person_main">
                            <div className="container_person-inf">
                                <div className="photo">
                                    <img className="img_person" src={Avatar}/>
                                </div>
                                <div className="container_person-2">
                                    { workInfo.InfoArea() }
                                    { profileInfo.InfoArea() }
                                    { locationInfo.InfoArea() }
                                    { messageInfo.InfoArea() }
                                </div>
                            </div>
                                <div className="container_person-job">
                                    { funJob.JobArea() }
                                    { batJob.JobArea() }
                                    { novaJob.JobArea() }
                                </div>
                        </div>
                    <div className="info_main">
                        <div className="container_info-1">
                            <p className="info_p">Привет! Мой общий опыт работы дизайнером больше 4 лет. Имею навыки от нативной анимации интерфейсов до 3D симуляций. В команде BAT IT занимался развитием дизайн системы, проектированием интерфейсов в сфере страхования. </p>
                            <p className="info_p">Позиционизирую себя как универсального Digital дизайнера с UI наклоном. Мой основной стек: Houdini (19.5), AE, C4D, Redshift (3.0.45), Karma XPU, Adobe, Figma. </p>
                        </div>
                        <div className="container_info-2">
                            <p className="bold_p">Программы</p>
                            <div className="container_info-3">
                                <div className="container_info-dsgn">
                                    <div className="prog">
                                        <img src={Houdini} />                                          
                                    </div>
                                    <div className="prog">
                                        <img src={Karma}/>
                                    </div>
                                    <div className="prog">
                                        <img src={Redshift}/>
                                    </div>
                                    <div className="prog">
                                        <img src={Cinema}/>
                                    </div>
                                    <div className="prog">
                                        <img src={AfterEffect}/>
                                    </div>
                                </div>
                                <p className="bold_p">Мои интересы и навыки</p>
                                <div className="container_info-attr">
                                    <p className="info_p">Моделирование</p>
                                    <p className="info_p">Интерфейсы</p>
                                    <p className="info_p">Digital дизайн</p>
                                    <p className="info_p">Прототипирование</p>

                                </div>
                                <div className="container_info-attr">
                                    <p className="info_p">VEX</p>
                                    <p className="info_p">JS</p>
                                    <p className="info_p">Flutter(Dart)</p>
                                    <p className="info_p">Shader Graph(Unity)</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
                <div className="sub_cont">
                    <p className='title_cont'>Последние работы</p>
                    <ProjArea />
                </div>
            </div>
        </div>
    </div>
  );
}

export default HomePage;
