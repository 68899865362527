

const Modal = () => {
    return (
        <div className="modal_container">
            <div className="modal">
                <div className="sub_modal">
                    <p>Утвердить</p>
                </div>
                <div className="sub_modal">
                    <p>Проверить</p>
                </div>
            </div>
        </div>
    )
}

export default Modal;