export const BottomNavLeft = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M10.9708 9.99449L10.9695 9.69941C10.9594 8.52901 10.8888 7.48478 10.7672 6.82302C10.7672 6.81109 10.6344 6.15476 10.5499 5.9363C10.4172 5.62053 10.1772 5.35249 9.87627 5.18267C9.63537 5.0615 9.38264 5 9.1181 5C8.91015 5.00964 8.5672 5.11428 8.32243 5.20202L8.11902 5.27997C6.77177 5.81513 4.19634 7.5638 3.20999 8.63319L3.13727 8.70794L2.81272 9.0582C2.60818 9.32623 2.5 9.65394 2.5 10.0064C2.5 10.3222 2.59636 10.638 2.78909 10.8932C2.84678 10.9758 2.93972 11.0819 3.02244 11.1715L3.33833 11.5022C4.42538 12.6036 6.77904 14.1485 7.99902 14.6604C7.99902 14.6714 8.7572 14.9881 9.1181 15H9.16628C9.71991 15 10.2372 14.6842 10.5017 14.1739C10.574 14.0343 10.6433 13.761 10.696 13.521L10.7908 13.0677C10.899 12.3389 10.9708 11.2209 10.9708 9.99449ZM16.2473 11.2653C16.9391 11.2653 17.5 10.6989 17.5 10.0004C17.5 9.30182 16.9391 8.73545 16.2473 8.73545L13.1646 9.00808C12.6219 9.00808 12.1819 9.45144 12.1819 10.0004C12.1819 10.5484 12.6219 10.9927 13.1646 10.9927L16.2473 11.2653Z" fill="#333333"/>
        </svg>
    )
}

export const BottomNavRight= () => {
    return (
        <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M9.52919 10.0055L9.53046 10.3006C9.5406 11.471 9.61115 12.5152 9.73283 13.177C9.73283 13.1889 9.86555 13.8452 9.9501 14.0637C10.0828 14.3795 10.3228 14.6475 10.6237 14.8173C10.8646 14.9385 11.1174 15 11.3819 15C11.5899 14.9904 11.9328 14.8857 12.1776 14.798L12.381 14.72C13.7282 14.1849 16.3037 12.4362 17.29 11.3668L17.3627 11.2921L17.6873 10.9418C17.8918 10.6738 18 10.3461 18 9.99357C18 9.6778 17.9036 9.36203 17.7109 9.10685C17.6532 9.02417 17.5603 8.91811 17.4776 8.82849L17.1617 8.49782C16.0746 7.39643 13.721 5.85153 12.501 5.33964C12.501 5.32862 11.7428 5.01193 11.3819 5H11.3337C10.7801 5 10.2628 5.31577 9.99828 5.82614C9.92601 5.96567 9.85669 6.23898 9.80396 6.47903L9.70919 6.93226C9.60101 7.6611 9.52919 8.77914 9.52919 10.0055ZM4.25271 8.73472C3.5609 8.73472 3 9.30108 3 9.99963C3 10.6982 3.5609 11.2645 4.25271 11.2645L7.3354 10.9919C7.87812 10.9919 8.31812 10.5486 8.31812 9.99963C8.31812 9.45162 7.87812 9.00734 7.3354 9.00734L4.25271 8.73472Z" fill="#333333"/>
        </svg>
    )
}